.header{
  width: 100%;
  height: 75px;
  border-bottom: 1px solid rgba(58, 51, 53, 0.12);
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.header_button{
  width: 46px;
  height: 46px;
  background-color: rgba(58, 51, 53, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_button svg{
  font-size: 18px;
  width: 1.256rem;
}

.position{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
}

.logo{
  cursor: pointer;
}

.account{
  display: flex;
  flex-direction: column;
  text-align: end;
}

.account span{
  font-family: 'InterBold';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.1px;
  color: rgb(58 51 53);
}

.account > span.account_role{
  font-size: 10px;
  color: rgba(43, 39, 40, 0.6);
}

@media (max-width: 600px){

  .position{
    display: block;
    padding: 0 12px;
  }
  .account span{
    font-size: 10px
  }
}