.merge{
  font-family: Inter;
  font-size: 14px;
}

.merge_value{
  border: 1px solid #7a7a7a4a;
  background-color: #f5f5f5;
  font-weight: 800;
  font-family: 'Inter';
  min-height: 34px;
}

.old{
  border: 3px solid #ed5e68;
  background-color: #ffe7e9;
}

.new{
  background-color: #e7ffed;
  border: 3px solid #256029;
}